<template>
  <div>

    <!-- dados operador: {{ obj_user }} -->
    <!-- {{ items_logs }} -->
    <!-- {{ items_niver }} -->
    <!-- {{ value_expansion }} -->

    <v-row class="grey lighten-3 ma-1">

      <!-- COLUNA ESQUERDA -->
      <v-col class="pa-1 " cols="12" md="8" lg="9">
        <v-row dense>
          <v-col class="red lighten-5"
            cols="12"
            :md="(value_expansion>=0) ?  10 : '5'"
            :lg="(value_expansion>=0) ?  6 : '3'"
          >
            <template>
              <v-card 
                flat
                elevation="5"
                class="grey lighten-3 mb-4"
                >
                  <v-expansion-panels 
                    :multiple="false"
                    :focusable='false'
                    v-model="value_expansion"
                    >

                    <!-- ANIVERSARIO -->
                    <v-expansion-panel >
                      <v-expansion-panel-header 
                      class="pa-2 px-4"
                      style="min-height: 28px"
                      @click="abrir_niver(),expansion_selecionao='NIVER'"
                      >

                        <div>
                          <v-row>
                            <v-col cols="12">
                                <v-icon size="26" class="pr-1" color="primary"> 
                                  mdi-cake-variant-outline 
                                </v-icon> 
                                
                                <span class="fs-14">Aniversários 
                                  <span v-if="items_niver.length>0" class="count_item fs-14">{{ items_niver.length }}</span>
                                </span>

                                  
                            </v-col>
                          </v-row>
                        </div>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <!-- class="py-0 ma-0" -->

                          <v-container>
                            <v-row>
                              <v-col class="px-0 py-0 red ">

                              <v-list
                                dense
                                style="max-height: 222px"
                                class="overflow-y-auto px-0 py-0"
                                >
                                  <v-list-item-group active-class="border">

                                    <v-list-item
                                      v-for="(item, i) in items_niver"
                                      :key="i"
                                      class="px-0 py-0 grey lighten-5"
                                      style="min-height: 20px !important;"
                                      
                                    >

                                      <v-list-item-content
                                      class="py-0"
                                      >
                                          <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                            <v-row>

                                              <v-col cols="10">
                                                <span style=" text-align: right; font-size: 9px;" class="grey--text pl-1  "> {{ i + 1 }}º &nbsp </span>

                                                <span style="font-size: 12px;" class="pl-0"> {{ item.x_DATA_NASCIMENTO }} </span>

                                                <span style="font-size: 12px;" class="primary--text bold pl-1"> {{ item.IDADE }} anos</span>


                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                      class="pl-2"
                                                      color="green"
                                                      size="20"
                                                      @click="before_enviar_whats(item, 'NIVER')"
                                                      :disabled="(!item.CELULAR)"

                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                      mdi-whatsapp
                                                    </v-icon>

                                                  </template>
                                                  <span>  Enviar Parabéns </span>
                                                </v-tooltip>


                                                <v-tooltip bottom>
                                                  <template v-slot:activator="{ on, attrs }">
                                                    <v-icon 
                                                      class="pl-2"
                                                      color="primary"
                                                      size="20"
                                                      @click="abrir_cadastro(item.CODIGO_ALUNO)"

                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                      mdi-account
                                                    </v-icon>

                                                  </template>
                                                  <span> Abrir Cadastro </span>
                                                </v-tooltip>



                                                <span>
                                                  <h5 style="display: inline; font-size: 12px;" class="ml-0 pl-1"> 
                                                  {{ item.NOME }}
                                                  </h5>
                                                </span>

                                              </v-col>
                                            </v-row>
                                          </div>

                                          <v-divider class="mx-0"/>
                                      </v-list-item-content>
                                    </v-list-item>




                                  </v-list-item-group>
                                </v-list>

                              </v-col>
                            </v-row>
                          </v-container>

                      </v-expansion-panel-content>
                    </v-expansion-panel>


                  </v-expansion-panels>
              </v-card>
            </template>
          </v-col>
        </v-row>

      </v-col>

      <!-- COLUNA DIREITA -->
      <v-col class="grey lighten-3 px-1" cols="12" md="4" lg="3" v-if="(obj_user.SUPER == 'S')">
        <v-card class="mx-auto">
          <!-- <v-card-title>Histórico</v-card-title> -->

          <div class="mb-2 my-1">
            <v-row
              align="center"
              class="mx-0"
            >
              <v-col>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">

                      <a
                       v-bind="attrs"
                       v-on="on"
                      > 
                        <h3 @click="abrir_logs(in_today)">
                          Histórico
                          <span v-if="items_logs.length>0" class="count_item fs-14 ml-n1">{{ items_logs.length }}</span>
                        </h3>
                      </a>
                    </template>
                    <span> Atualizar Histórico </span>
                  </v-tooltip>


              </v-col>
              <v-col cols="7" class="white lighten-4 ma-1">
                <v-text-field v-model="in_today"
                  label="Data"
                  hide-details
                  type="date"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            <v-container>
              
              
              <v-row class="mt-1 px-0 ml-n3">
                <v-col class="pa-0  mt-n6 mb-1" cols="12">
                  <v-text-field v-model="search_logs"
                    dense
                    clearable
                    append-icon="mdi-magnify"
                    label="Procurar"
                    single-line                
                    hide-details
                    @keyup.esc="search_logs='',filtrar_logs('')"
                    @keypress.enter="filtrar_logs(search_logs)"
                    @click:clear="filtrar_logs('')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              
              <v-row class="pt-1">
                <v-col class="px-0 py-0 ">
                <v-list
                  dense
                  class="overflow-y-auto px-0 py-0"
                  :height="retorna_altura(60)"
                  >
                    <v-list-item-group active-class="border">

                      <v-list-item
                        v-for="(item, i) in items_logs"
                        :key="i"
                        class="px-0 py-0 grey lighten-4"
                        style="min-height: 20px !important;"
                        three-line
                        
                      >

                        <v-list-item-content
                        class="py-0"
                        >

                            <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                              <v-row>

                                <v-col>
                                  <span>
                                    <h5 style="display: inline; font-size: 10px;" class="ml-1"> {{ item.HORAS }} </h5>  
                                    <h5 style="display: inline; font-size: 10px;" class="ml-1"> {{ item.OPERADOR }} </h5>  
                                  </span>
                                  <!-- <span style="display: inline; font-size: 8px;">{{ item.QT }}</span> -->
                                  <v-list-item-subtitle style="display: inline; font-size: 11px;" class="ml-1">
                                      {{ item.DESCRICAO }} 

                                      <span v-if="(item.CODIGO_ALUNO > 0)">
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                              class="pl-2"
                                              color="primary"
                                              size="18"
                                              @click="abrir_cadastro(item.CODIGO_ALUNO)"

                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              mdi-account
                                            </v-icon>

                                          </template>
                                          <span> Abrir Cadastro </span>
                                        </v-tooltip>

                                      </span>
                                      <!-- [{{ item.CODIGO_ALUNO }}] -->
                                </v-list-item-subtitle>

                                </v-col>
                                

                              </v-row>
                            </div>

                            <v-divider class="mx-0"/>
                        </v-list-item-content>
                      </v-list-item>




                    </v-list-item-group>
                  </v-list>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-col>

    

    <!-- coluna lateral direita -->
    <v-col  cols="3" class="py-0" v-if="0>1">
      <v-row class="grey lighten-3 pa-0">
        <v-col class="px-1">

          <v-card 
            flat
            elevation="5"
            class="mt-1 grey lighten-3 mb-4"
            >
              <v-expansion-panels 
                :multiple="false"
                :focusable='false'
                v-model="value_expansion"
                >

                <!-- WHATSAPP -->
                <v-expansion-panel >
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  @click="minhas_tarefas(),expansion_selecionao='WHATSAPP'"
                  >

                    <div>
                      <v-row>
                        <v-col cols="12">
                            <v-icon size="26" class="pr-1" color="primary"> 
                              mdi-whatsapp
                            </v-icon> 
                            
                            <div class="fs-14">WhatsApp
                            </div>
                              
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- class="py-0 ma-0" -->
                      <v-container>

                        <v-row>
                          <v-col class="px-0 py-0 red ">

                            <v-fab-transition>
                              <v-btn
                                v-show="(value_expansion>=0)"
                                color="primary"
                                dark
                                absolute
                                right
                                fab
                                x-small
                                @click="abre_janela_modal(true, true, 'WHATSAPP', 'WHATSAPP')"
                              >
                                <v-icon>mdi-menu</v-icon>
                              </v-btn>
                          </v-fab-transition>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


              </v-expansion-panels>
            </v-card>

        </v-col>
      </v-row>
    </v-col>
    </v-row>
  </div>
</template>

<script>


// import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
import { send_whats, primeiro_nome } from '../assets/js/DSi_basico'
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import { logado } from '../assets/js/DSi_rotinas'
import {get_data_hora} from '../assets/js/DSi_basico'
import {faz_backup, registra_log} from '../assets/js/DSi_login'


export default {

  data: () => ({

    items_niver :[],

    obj_user: {},//dados do operador

    expansion_selecionao:'',
    value_expansion: -1, //0 ou -1 ou null

    items_logs:[],
    items_logs_clone:[],
    search_logs :'',
    
    in_today: '1984-04-20',

    expansion_selecionao:'',
    value_expansion: -1, //0 ou -1 ou null
  }),

  created(){
    this.onCreated()
  },

  watch:{
    in_today(a){
       this.abrir_logs(a)
    }
  },

  methods:{


    filtrar_logs(nome){

      // const resultado = this.items_niver.filter(item => {
      // return item.toLowerCase().includes(nome.toLowerCase());
      // })

      this.items_logs = this.items_logs_clone.map((x) => x); //clonando array copiar array          

      if (nome){
        const resultado = this.items_logs.filter(elemento => elemento.DESCRICAO.toLowerCase().includes(nome.toLowerCase()));
        this.items_logs = resultado.map((x) => x); //clonando array copiar array          
      }
      else{
        // this.abrir_logs(this.in_today)
      }
    },

    async ler_sessions(){

      this.obj_user = JSON.parse(sessionStorage['obj_user'])//busco os dados do usuario operador
    },

     async before_enviar_whats(item, tipo){
        

       let empresa = sessionStorage['lg:EMPRESA']
       let quebra = '%0A'
       let quebra2 = '%0A%0A'
       let msg  

       if (tipo == 'NIVER'){
          let nome = await primeiro_nome(item.NOME)
          msg = `
            Olá *${nome}*, gostaríamos de lhe desejar um 🎉 *Feliz Aniversário* 🎊 e que você aproveite muito bem este dia tão especial! 🥳`+ 
            `${quebra+quebra}São os votos do *${empresa}*`


          msg = `🎉 *Feliz Aniversário, ${nome}* 🎊`+ quebra2+

                `Gostaríamos de lhe desejar um ano repleto de saúde, alegrias e conquistas.`+quebra2+

                `*Aproveite muito bem o seu dia!*`+quebra2+

                `Grande Abraço, `+quebra+ 
                `*${empresa}*`

            this.enviar_whats_manual(item, msg)

            await registra_log('WHATSAPP', `ENVIOU WHATS PARABENS para ${item.NOME}`, item.CODIGO_ALUNO)

            await this.abrir_logs(this.in_today)//atualiza historico
       }
     },

     enviar_whats_manual(item, msg){

       send_whats(item.CELULAR, item.NOME, item.CODIGO_ALUNO, msg)
    },

    async abrir_cadastro(ide){
      sessionStorage['cadastro_abrir_ide'] = ide
      this.$router.push('/cadastro')//redireciono para a pagina do router
    },

    async abrir_niver(){


      if (this.value_expansion==''){ 
          return false//so executa se estiver aberto
      }

      
      let sql = 
      `
        SELECT
            ' hoje' AS TIPO,
            EXTRACT(MONTH FROM ALUNOS.DATA_NASCIMENTO) AS MES,
            EXTRACT(DAY FROM ALUNOS.DATA_NASCIMENTO) AS DIA,
            ALUNOS.DATA_NASCIMENTO,
            ALUNOS.DATA_CADASTRADO,
            ALUNOS.CODIGO_ALUNO,
            ALUNOS.NOME, 
            ALUNOS.CELULAR,
            ALUNOS.WHATS_NIVER,
            TIMESTAMPDIFF(YEAR, ALUNOS.DATA_NASCIMENTO, CURRENT_DATE) AS IDADE
        FROM
            ALUNOS
        WHERE
            (ALUNOS.DATA_CADASTRADO >= DATE_SUB(CURRENT_DATE, INTERVAL 96 MONTH)) AND
            (EXTRACT(DAY FROM ALUNOS.DATA_NASCIMENTO) = DAY(CURRENT_DATE)) AND
            (EXTRACT(MONTH FROM ALUNOS.DATA_NASCIMENTO) = MONTH(CURRENT_DATE))
        ORDER BY
            NOME

      `
      // console.log('estatisticas_formas:', sql);
      await DSisql.crud_abrir_tabela(this, sql, 'items_niver')

    },

    async onCreated(){

      if (await logado(this)){

        await this.showDrawer(true)


        // NEW
        // await DSisql.crud_empresa()
        // await faz_backup()
        // NEW





        this.in_today = get_data_hora('today')

        await this.ler_sessions()

        await this.abrir_logs(this.in_today)

        await this.abrir_niver()
      }

    },

    async showDrawer(value){
        this.$emit('executar', value)//mostrar ou nao paineis
        this.can_show = true
        this.isMobile = window.innerWidth < 600
    },

    async abrir_logs(data){
      let sql = `select * from registro_log where data = '${data}' order by codigo desc`
      // await this.crud_abrir_tabela(sql, 'items_lista')
     if (await DSisql.crud_abrir_tabela(this, sql, 'items_logs')){
        this.items_logs_clone = this.items_logs.map((x) => x); //clonando array copiar array 
        // console.log('this.items_logs_clone:', this.items_logs_clone);
     }

    },


    retorna_altura(menos){
        // let r = '100vh'
        // let r = '720'
        let r = (window.innerHeight - 80 - 60)
        let height = window.innerHeight;

        if (menos){
          r = (r - menos)
        }

        return r
    },

  },

}
</script>

<style scoped>

  .count_item {
    position: relative !important;
    top: 0px;
    font-size: 80%;
    vertical-align: super;
    color: red;
  }

  .inline{
    display: inline  !important;
  }
</style>