<template>
  <div class="background-div">

    <!-- 12052009 -->

    {{ (TESTE)? 'TESTE':'' }}

    <v-app-bar height="70" color="blue" v-if="0>1">

    </v-app-bar>
  
    <!-- d-flex align-center justify-center -->
  <center class="pt-5 mx-10">
      <!-- CARD LOGO DSI -->
    <v-card
          :class="[(isMobile) ? '' : 'grey white--text text-center pa-0']"
          elevation="4"
          width="360"
          v-if="(versao_anterior)"
        >
        

        <v-row dense>
              <v-col cols="8" class="pr-0 pl-2">
                <v-card tile flat>
                  <v-card-title class="justify-center">
                    <div 
                      :class="[(isMobile) ? 'text-h5':'text-h3', 'font-weight-normal grey--text']"
                    >Sistemas</div>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="4" class="pl-0 pr-2">
                <v-card tile flat>
                  <v-card-title class="justify-center blue lighten-0 white--text" >
                    <div class="text-h3 font-weight-medium ">DSi</div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>


          <div class="white pa-1" v-if="0>1">
            <v-row dense class="grey lighten-3 pa-0 ma-0">

              <v-col class="pa-1 px-2 grey lighten-3 mt-1">
                <span class="font-weight-regular pa-0 ma-0 text-h3 black--text">Sistemas</span>
              </v-col>

              <v-col class="pa-1 px-2 blue lighten-0">
                <span class="font-weight-regular pa-0 ml-1 text-h2">DSi</span>
              </v-col>

            </v-row>
          </div>
    </v-card>
    <v-divider class="grey pt-0 mt-5" v-if="(versao_anterior)"/>
  </center>



    <h1 class="text-center mt-10"> {{ nome_empresa }} </h1>

    
    <div class="white--text mt-10" v-if="(TESTE)">
    
      obj_dados_cliente: {{ obj_dados_cliente }} <br/>
      {{ dialog_modal_acao }}
      {{ codigo_celular }}
      etapa:{{ etapa }} <br/>
      token:{{ token }} <br/>
      ide:{{ ide }} <br/>
      item_edita:{{ item_edita }} <br/><br/>
      {{ snack_timeout }}
    </div>




  <v-snackbar
  v-model="snackbar"
  :timeout="snack_timeout"
  :color="snack_color"   

  centered
  vertical
  multi-line
  
  
  class="mb-4"
  attrs
  elevation="24"
  width="900px"
  >
  

  <!-- {{ snack_text1 }}<br/>
  {{ snack_text2 }} -->

  <span  :class="[(isMobile) ? 'text-h6' :'text-h6']" style="font-weight: normal !important;">{{ snack_text1 }}</span>
  <br/>
  <span v-if="snack_text2" :class="[(isMobile) ? 'text-h6' :'text-h6']" style="font-weight: normal !important;">{{ snack_text2 }}</span>
    

    <template v-slot:action="{ attrs }">
      <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          fechar
        </v-btn>
      <!-- <v-icon
        color="white"
        v-bind="attrs"
        >mdi-chat-processing
      </v-icon> -->
    </template>
  </v-snackbar>


  <div class="ml-3 mb-2 mt-10  text-center" v-if="(banner)">
    <v-chip large color="blue" dark class="pa-5" @click="dialog_tabela=true, banner=false, oi()">
        <!-- <h3> Assinar Agora</h3> -->
        <h3>
          Testar Grátis por 15 dias
        </h3>
    </v-chip>
  </div>


  <template v-if="(versao_anterior)">
        <br>
        <v-card
          class="mx-auto"
          color="blue"
          dark
          max-width="540"
          v-if="(banner)"
        >

        <v-img
          :src="backgroundImage"
          aspect-ratio="2.5"
          gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)"
        >
        
          <v-card-title>
            <v-icon
              left
              size="77"
              v-if="0>1"
            >
              mdi-calendar-clock
            </v-icon>
            <!-- <span class="text-h4 font-weight-bold yellow--text">Módulo</span>  -->
              
          </v-card-title>

          <div class="text-h4 text-center font-weight-bold ml-3 mt-1 blue--text">Gerenciador de CFC Online </div>

          <v-card-text class="text-h6 font-weight- text-left white--text">
          <span>
            <!-- Aumente a eficiência da sua <span class="white--text"> Clínica</span> com o Módulo Agendamento Online -->
            Aumente a produtividade no seu CFC e tenha tudo num só clique
          </span>

          <br/>
          <span>
            <!-- Sua agenda disponível para os pacientes 24h por dia, 7 dias por semana -->
          </span>
          
          </v-card-text>

          <v-card-text class="text-h6 font-weight-bold text-left grey--text">
              <!-- Mais agilidade no seu dia a dia  -->
              Mais eficiência à sua equipe
              <br/>
              <!-- Economize tempo valioso para sua equipe -->
              Mais tempo para você 
          </v-card-text>



          <v-card-text class="text-h6 font-weight- text-left white--text">
          <span>
            Apenas 169,90 <span style="font-size:12px !important"> / mês</span>
          </span>
          
          </v-card-text>


          <div class="ml-3 mb-2  text-center">
            <v-chip large color="blue" class="pa-5" @click="dialog_tabela=true, banner=false">
               <!-- <h3> Assinar Agora</h3> -->
               <h3>
                 Testar Grátis por 15 dias
                </h3>
            </v-chip>
          </div>


          

          <v-card-actions>
            <v-btn
              color="yellow lighten-2"
              text
              @click="show = !show"
            >
              Funcionalidades
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              icon
              @click="show = !show"
            >
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>


          <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>

            <v-card-text>
              - Cadastro de Alunos<br/>
              - Controle de Pagamentos e Recebimentos <br/>
              - Previsão de Recebimentos <br/>
              - Imprima Contratos e Recibos com um clique <br/>
              - Acesse de qualquer lugar a qualquer hora <br/>
            
            </v-card-text>
          </div>
        </v-expand-transition>

        </v-img>
        </v-card>
      </template>


  <v-dialog v-model="dialog_modal"
      width="500"
      persistent
      scrollable
    >

      <v-card style="white-space: normal !important">
        <v-card-title class="text-h6 grey lighten-3 justify-center">
          {{dialog_modal_titulo}} 
        </v-card-title>

        <v-card-subtitle v-html="dialog_modal_subtitulo" class="text-subtitle-1 mt-4 pb-0 mb-0"/>


        <v-card-text class="">
          <!-- <v-img
            alt="user"
            :src="dialog_img_url"
            height="30%"
            v-if="(dialog_img_url)"
          >
         </v-img> -->

         <v-container class="mt-4">
         <v-row dense>
           <v-col cols="12" class="">
                <v-text-field
                v-model="comparar_codigo"
                label="Digite o Código Recebido"
                id="digite_codigo"
                ref="digite_codigo"
                outlined
                dense
                v-if="(codigo_celular) && (!codigo_ok)"
                hide-details=""
                class="ml-2"
                autofocus
                >
              
              </v-text-field>
          </v-col>
        </v-row>
      </v-container>

        </v-card-text>

        <v-card-actions class="pt-0">
          <!-- <v-btn
            color="primary"
            text
            x-large
            @click="saiba_mais_click(dialog_img_mais)"
            v-if="dialog_img_mais"
          >
            saiba mais
          </v-btn> -->

          <v-spacer></v-spacer>


          <v-btn
            color="primary"
            text
            x-large
            @click="dialog_modal = false, clicou_modal_fechar(dialog_modal_acao)"
          >
          {{ (dialog_modal_acao_name)? dialog_modal_acao_name : 'FECHAR' }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>


  <v-dialog v-model="dialog_tabela"
        persistent
        max-width="990"
        scrollable
    >
        <v-card class="" >


          <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>



          <v-card-title class="pb-4 mb-2 ml-n2 grey lighten-3" >
            <!-- <span class="text-h4 font-weight-bold ">Gerenciador de Auto Escola</span> -->
            <span class="text-h5 font-weight-bold ">Cadastrar {{ etapa }}</span>

          </v-card-title>



            <!-- passo 4 monto os campos editaveis -->
            <!-- :readonly = "readonly" -->
                <v-card-text class="pa-1">
                  <v-form
                    ref="form"
                    v-model="valido"
                    >
                    <v-container>
                        <v-row >
                            <v-col
                                class=""
                                v-for="(item, i) in items_editaveis"
                                :key="i"
                                :cols="(isMobile) ? 12 : item.cols"
                                v-if="parseInt(item.cols)>0"   
                            >


                                <v-btn v-if="(item.tag == 'v-btn')" :color="item.color"
                                @click="modal_clicou(item.click)"
                                >
                                  {{item.text}}
                                </v-btn>


                                <span v-if="item.tag == 'span'" :class="[(item.color)? [item.color]+'--text' : '']">
                                  <b> {{ item.text }}</b>
                                </span>

                                <div v-if="item.grupo" class="mt-n7 pb-2">
                                  <b> {{ item.grupo }}</b>
                                </div>

                                <!-- COM V-MASK -->
                                <v-text-field 
                                    dense
                                    v-model="item_edita[item.value]"
                                    :label="item.rule == 'S' ? item.text+' *': item.text"
                                    :type="item.type"
                                    v-if="(item.tag == 'v-text') && (item.vmask)"
                                    @keyup="meu_keyup($event, item.value, item.upper)"
                                    :rules="item.rule == 'S' ? notnullRules: noRules"
                                    :autofocus="i==0"
                                    :hint="item.hint"
                                    v-mask = "getVmask(item.vmask)"
                                ></v-text-field>
                                
                                <!-- SEM V-MASK  acaba dando erro, por isso separei-->
                                <v-text-field
                                    dense
                                    v-model="item_edita[item.value]"
                                    :label="item.rule == 'S' ? item.text+' *': item.text"
                                    :type="item.type"
                                    v-if="(item.tag == 'v-text') && (!item.vmask)"
                                    @keyup="meu_keyup($event, item.value, item.upper)"
                                    :rules="item.rule == 'S' ? notnullRules: noRules"
                                    :autofocus="i==0"
                                    :hint="item.hint"
                                ></v-text-field>



                                <v-combobox
                                    dense
                                    v-model="item_edita[item.value]"
                                    :label="item.text"
                                    v-if="item.tag == 'v-autocomplete'"
                                    :items="item.tag_items"
                                    :rules="item.rule == 'S' ? notnullRules: noRules"
                                ></v-combobox>
                                
                                <v-select
                                    :class="[item.class]"
                                    dense
                                    v-model="item_edita[item.value]"
                                    :label="item.text"
                                    v-if="item.tag == 'v-select'"
                                    :items="item.tag_items"
                                    :rules="item.rule == 'S' ? notnullRules: noRules"

                                    item-value='CODIGO'
                                    :item-text='item.tag_item_text'
                                    :clearable = "item.tag_clear == 'S'"
                                    :persistent-hint="item.cols>2"
                                    :hint="item.hint"
                                >
                                
                                <!-- <template slot="selection" v-slot-scope="item"> -->
                                  
                                <!-- <template slot="selection" v-slot-scope="item"> -->
                                  <template v-slot:label>
                                    <v-tooltip bottom v-if="item.hint">
                                        <template v-slot:activator="{ on, attrs }">
                                          <span
                                              v-bind="attrs"
                                              v-on="on"
                                          > 
                                            <span :class="item.class">
                                              {{ item.text }} 
                                              <!-- {{ item_edita[item.value] }} -->
                                            </span>
                                          </span>

                                        </template>
                                        <span> {{ item.hint }} </span>
                                      </v-tooltip>

                                      <span :class="item.class" v-else>
                                        {{ item.text }} 
                                      </span>
                                  </template>
      
                              </v-select>


                                <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                                <v-textarea
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-textarea'"
                                  :hint="item.hint"
                                  :persistent-hint="item.cols>2"
                                  dense
                                >
                                </v-textarea>




                                <!-- INICIO DIV IMAGEM -->
                                <div class=""
                                  v-if="item.tag == 'img'"
                                >
                                
                                <v-row dense class="grey lighten-5">
                                  <v-col  class="">
                                    <span class="pb-2">Logo</span>
                                      <v-img
                                      v-if="urlLogo"
                                      max-height="90"
                                      max-width="170"
                                      :src="urlLogo"
                                      contain
                                    >
                                    </v-img>
                                  </v-col>
                                  
                                  <v-col  class="mt-5">
                                    <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                    <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                    <!-- <p>Remover</p> -->
                                    <v-btn text x-small color="red" for="file_upload"
                                      @click="item_edita[item.value] = '', urlLogo=''"
                                    >
                                      Remover
                                    </v-btn>
                                  </v-col>


                                  </v-row>
                                  <!-- oculto -->
                                  <v-row dense class="grey lighten-3">
                                    <v-col>
                                      <!-- necessario para fazer upload logo -->
                                      <v-file-input
                                        v-model="files_upload"
                                        accept="image/*"
                                        label="Logomarca"
                                        @change="upload_logo(files_upload, session_bd ,'logo',item.value)"
                                        style="display: none"
                                        id="file_upload"
                                      >
                                      <!-- session_bd = nome da pasta onde salvar as imagens..peguei pelo nome do banco de dados -->
                                      </v-file-input>
                                      <!-- necessario para fazer upload logo -->
                                    </v-col>
                                  </v-row>
                                </div>
                                <!-- FINAL DIV IMAGEM -->
                                
                            </v-col>


                        </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

        <v-card-actions>

            <v-spacer></v-spacer>


            <v-btn
                color="red"
                text
                @click="testar_email_zap()"
                class="mr-4"
                v-if="(TESTE)"
            >
              TESTE EMAIL E ZAP
            </v-btn>


            <v-btn
                color="red"
                text
                @click="dialog_tabela = false, (banner = ide==0)"
                class="mr-4"
            >
              Cancelar
            </v-btn>
            
            

            <v-btn
              :disabled="(!valido)"
              v-if="(!codigo_ok)"
              color="success"
              @click="receber_codigo(item_edita.CELULAR)"
              class="mr-2"
              >
              <v-icon left>
                  mdi-whatsapp
              </v-icon>
              Receber CÓDIGO
              </v-btn>
                        

            <v-btn
                :disabled="!valido"
                v-if="(codigo_ok)"
                color="primary"
                @click="botao_salvar(etapa)"
                class="ml-2"
            >
              Salvar
            </v-btn>

          </v-row>
        </v-card-actions>
        </v-card>
  </v-dialog>

  </div>
</template>

<script>

import axios from "axios";
var url =  sessionStorage['url']

import {crud_sql_canal, crud_abrir_tabela_canal, crud_abrir_tabela_ponte, crud_sql_ponte} from '../assets/js/DSi_login'
import {get_data_hora,  gerar_token, consulta_cep} from '../assets/js/DSi_basico'
import { send_whats_api_45, enviar_email_45 } from '../assets/js/DSi_whats'

export default {

  data: () => ({ 

    TESTE: false,

    nome_empresa:'',

    obj_dados_cliente:null,

    token: '',
    ide: '',


    versao_anterior: false,

    overlay: false,

    show: false,
    banner: false,
    dialog_tabela: false,


    backgroundImage:'',

    dialog_modal: false,
    dialog_modal_titulo: '',
    dialog_modal_subtitulo: '',
    dialog_modal_acao :'',
    dialog_modal_acao_name :'',

    codigo_celular:'',
    comparar_codigo:'',
    codigo_ok: false,
    

    etapa: '',
    items_lista: [],
    items_header: [],
    items_editaveis: [],
    item_edita:  {"TIPO_CLIENTE": "CFC WEB"},
    // item_edita: { "CNPJ_CPF": "33.333.333/3333-33", "NOME": "NOVO CLIENTE", "TIPO_CLIENTE": "CFC WEB", "DIA_VENCIMENTO": "15", "CELULAR": "(44) 99968-5172", "EMAIL": "dsinaweb@gmail.com", "INDICACAO": "GOOGLE", "CEP": "87040-220", "ENDERECO": "rua das ruas ", "CIDADE": "maringa", "BAIRRO": "SAO PAULO", "UF": "PR", "FONE": "(44)3034-6479", "USER_USUARIO": "CLEBERSON ALVES", "USER_EMAIL": "dsinaweb@gmail.com", "USER_X_EMAIL": "dsinaweb@gmail.com" },
    

    img_fundo:'http://localhost/images/black2.jpg',

    isMobile:false,
    

    valido: false,
  
    cor_fundo: '#273746',

    snackbar: false,
    snack_text1: "texto\ndois",
    snack_text2: "",
    snack_color: "success",
    snack_timeout: "3000",


    emailRules: [
         // v => !!v || 'necessário',
         v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
    ],

    notnullRules: [
      v => !!v || 'necessário'
    ],

    noRules:[v => true],

  }),


  watch: {

      comparar_codigo(a){
          this.codigo_ok = (a == this.codigo_celular)

          if (this.codigo_ok){
            this.dialog_modal = false

            this.alertaAqui('Código Validado com sucesso!','Clique em Salvar para continuar','blue',3000)
          }
      }
  },

  created(){
    this.isMobile = window.innerWidth < 600
    this.onCreated()
  },

  methods:{


    async dados_cliente(ide){

      // alert(ide)
      let retorno   = null
      let sql_query = `select nome from empresa`
      
      retorno = await crud_abrir_tabela_ponte( ide , sql_query)
      // console.log('retorno:', retorno);

      this.nome_empresa = retorno[0].nome

      if (!this.nome_empresa){
          // alert('...')
          window.location.replace("https://www.google.com") 
      }

    },

    transformarData(data) {
      // Divide a data no formato dd/mm/yyyy
      const [dia, mes, ano] = data.split('/');
      
      // Retorna a data no formato yyyy-mm-dd
      return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
    },

    async trata_token(){

      let vv = this.$route.params.token//pega o parametro do url
      let ide 

      if (vv !== '@12052009'){//padrao
         vv = this.validarNumero(vv)
        
         ide = vv / 777888
         if (Number.isInteger(ide)){
            // console.log('inteiro');
         }
         else{
          // console.log('quebrado');
          ide = 0
         }
      
      }
      else{
        ide = 0
      }


      this.ide   = ide
      this.token = vv
    },


    validarNumero(entra) {
      // Remove qualquer caractere que não seja número
      const valor = entra.replace(/[^0-9]/g, '');
      this.numero = valor;

      if (valor>0){
         return valor
      }
      else{
         return 0
      }

    },

    async testar_email_zap(){


      let senha = 'xxxx'
      let msg_whats = `
*Acesse*:
 *dsi.app.br*

 *Usuário:*  *Digite seu CPF*
 *Senha:*     ${senha}

*Vídeo de Demonstração do DSi CFC* 
 https://youtu.be/saMCoXXFuQg

Qualquer dúvida estaremos à disposição.
*Sistemas DSi*
          `

          //envia para o whatsapp tambem a senha
          let img = `https://dsi.app.br/logos/nova_dsi2.png`
          await send_whats_api_45('NOVO_CLIENTE', 'novo_cliente.vue', 'DSI -'+ 'nomeee' , '44999685172', msg_whats, null,null,img);//envia zap





          let msg_email = `Olá <b>{dados.USER_USUARIO}</b> sua senha provisória é: <b>${senha}</b> `
          // adicionou senha com sucesso..agora envia email
          try {

              if (rr =  await enviar_email_45('contato.dsi@gmail.com', 'Novo Login', msg_email)){
                  console.log('r:', rr);
                  if (rr == 'OK'){

                    this.abre_modal('Verifique seu E-mail', `Sua <b>senha</b> foi enviada para seu Email! <br> Verique sua caixa de <b>entrada</b> e também sua caixa de <b>Spam</b>`,'redirect', 'ACESSAR app')

                  }
                  else{
                    this.abre_modal('E-mail não enviado', 'Não foi possível enviar o e-mail','','')
                  }
              }

          } catch (error) {

            console.log('error:', error);

            setTimeout(function(){
              this.abre_modal(`Solicitando sua senha por e-mail`,`<br>Na janela de login do <b>App</b> clique em <b>Esqueci minha Senha</b> para que seja enviada uma nova senha para seu e-mail`,'redirect','acessar app')

            }.bind(this), 4000);
          }
          

    },

    oi(){
      // uso esse oi para testar aglo
      // this.avisa_zap_45('44999685172','Ola sec virtual pula ai vai')
    },

    async avisa_zap_45(numero, msg){

      const data = {
                acao:       'AVISAZAP',
                msg:         msg,
                numero:      numero,
                empresa:     'NOVO_CLIENTE',
      }  

      const header =   { headers: {'Content-Type': 'multipart/form-data', }}//se nao tiver esse header..da erro

      const res = await axios.post(url+'/clientes/forms.php', data, header)

      console.log('res:', res.data);
    },

    abre_modal(titulo, subtitulo, acao, btn_name){
      this.dialog_modal_titulo    = titulo
      this.dialog_modal_subtitulo = subtitulo
      this.dialog_modal           = true
      this.dialog_modal_acao      = acao
      this.dialog_modal_acao_name = btn_name
    },

    clicou_modal_fechar(acao){

      if (acao == 'redirect'){
        let link = "https://dsi.app.br"
        // window.location.replace(link) //impede retornar pelo historico
        location.href = link
      }

    },


    alertaAqui(text1, text2, color, time){

      if (!time){
          time = 2000
      }

      this.snackbar      = true;
      this.snack_text1   = text1;
      this.snack_text2   = text2;
      this.snack_color   = color;
      this.snack_timeout = time;

      if ( (text1?.includes('ERROR:')) || (text1?.includes('error'))){ 
          that.snack_color = 'red';
      }
    },

    async receber_codigo(celular){
      
      this.abre_modal(`Verificação de Conta`, `Código enviado para o número ${this.item_edita.CELULAR}`)

      
      if (celular.length < 15){
          alert('Preencha o campo WhatsApp da Empresa!')
          return false
      }

      const token = Math.floor(Math.random() * 900000) + 100000;
      this.codigo_celular = token


      let texto = `*Sistemas DSi*:\n*${this.item_edita.NOME}* Seu código de verificação é *${token}*`

  

      await send_whats_api_45('NOVO CLIENTE - VALIDAR', 'MODELO', 'DSI -'+this.item_edita.NOME , celular, texto);//envia zap



      setTimeout(function(){
          this.focus_by_id('digite_codigo')
      }.bind(this), 222);
    },

    botao_salvar(etapa){

      if (etapa == 'Conta'){
        this.salvar_cadastro()
      }
      else if (etapa == 'Login'){
        this.salvar_senha()
      }
      else if (etapa == 'Aluno'){
        this.salvar_aluno()
      }
    },

    async onCreated(){

      await this.trata_token()
      // await this.dados_cliente(this.ide)
      

      this.codigo_ok = this.TESTE
      
      if (this.token == '@12052009'){

          this.tabela('Conta')
          alert('Crie sua Conta gratuitamente, cancele quando quiser!')
          this.dialog_tabela = true
      }
      else if (this.ide > 0){

          await this.dados_cliente(this.ide)

          this.tabela('Aluno')
          this.codigo_ok = true
          this.dialog_tabela = true
      }
      else{
        // alert( 'Redireciona...')
        window.location.replace("https://www.google.com") 
      }
      
      

      if (this.TESTE){
            this.item_edita = { "CNPJ_CPF": "33.333.333/3333-33", "NOME": "NOVO CLIENTE", "TIPO_CLIENTE": "CFC WEB", "DIA_VENCIMENTO": "15", "CELULAR": "(44) 99968-5172", "EMAIL": "dsinaweb@gmail.com", "INDICACAO": "GOOGLE", "CEP": "87040-220", "ENDERECO": "rua das ruas ", "CIDADE": "maringa", "BAIRRO": "SAO PAULO", "UF": "PR", "FONE": "(44)3034-6479", "USER_USUARIO": "CLEBERSON ALVES", "USER_EMAIL": "dsinaweb@gmail.com", "USER_X_EMAIL": "dsinaweb@gmail.com", "RG" : "85990357", "CPF":"044.177.289-77", "DATA_NASCIMENTO" : "20/04/1984", "OBSERVACAO":"OBSERVACAO LOGICA"  }
      }
    },


    async focus_by_id(id){
       document.getElementById(id).focus();
    },


    //PASSO 3
    async tabela(tabela){

        
        this.etapa = tabela//muda etapa
        //limpa array
        this.items_lista       = [] //recebe os dados do sql
        this.items_header      = [] //campos que tem que mostrar no header
        this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


        if (tabela=='Login'){

          this.items_header = [
              
              { text: 'Nome de Usuário'   ,  value: 'USER_USUARIO',tag:'v-text', type:'text', cols:'6',rule:'S' },
              { text: 'CPF'               ,  value: 'USER_CPF',tag:'v-text', type:'text', cols:'6', width: this.w_cpf, rule:'S', vmask:'cpf' },

              // { text: '',tag:'span',cols:'12'},//linha ou espaço quebra de linha

              // { text: 'Senha',           value: 'USER_SENHA',tag:'v-text', type:'text', cols:'3',rule:'S' },
              { text: 'Email',           value: 'USER_EMAIL',tag:'v-text', type:'text', cols:'6',rule:'S', hint:'Para recuperação de senha' },
              { text: 'Confirmar Email', value: 'USER_X_EMAIL',tag:'v-text', type:'text', cols:'6',rule:'S' },

              { text: 'A Senha será enviada para o seu e-mail',tag:'span',cols:'6', color:'blue'},//linha ou espaço

          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

        }
        
        else if (tabela=='Conta'){

          this.items_header = [
              
              // { text: 'Tipo', value: 'TIPO_CLIENTE',type:'text',tag:'v-select',tag_items:['CFC WEB','CFC','MEDCLINIC','CIVIC','CLINICA'],cols:'2', rule:'S', width:'90px'},
              
              { text: 'Nome da Empresa', value: 'NOME', tag:'v-text', type:'text', cols:'5', width: this.w_nome, rule:'S', width:'210px' },


              { text: 'CNPJ', value: 'CNPJ_CPF',tag:'v-text', type:'text', cols:'3', width: this.w_cnpj, rule:'S', vmask:'cnpj' },

              { text: 'WhatsApp Empresa', value: 'CELULAR',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'S',vmask:'cel' },
              { text: 'Fone Empresa', value: 'FONE',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'S',vmask:'fone' },
              { text: 'Dia Vencimento', value: 'DIA_VENCIMENTO',type:'text',tag:'v-select',tag_items:['10','15'],cols:'2', rule:'S'},

              { text: 'Email da Empresa', value: 'EMAIL',tag:'v-text', type:'text', cols:'6',rule:'S' },
          

              { text: 'Indicacao', value: 'INDICACAO',type:'text',tag:'v-select',tag_items:['GOOGLE','CLIENTES','WHATS','VENDEDOR','OUTROS'],cols:'2', rule:'N'},


              
              { text: 'CEP', value: 'CEP',tag:'v-text', type:'text', cols:'2',rule:'S',vmask:'cep' },
              { text: 'Endereço - Nº', value: 'ENDERECO',tag:'v-text', type:'text', cols:'5',rule:'S' },
              { text: 'Cidade', value: 'CIDADE',tag:'v-text', type:'text', cols:'3',rule:'S' },
              { text: 'Bairro', value: 'BAIRRO',tag:'v-text', type:'text', cols:'3',rule:'S' },
              { text: 'UF', value: 'UF',tag:'v-text', type:'text', cols:'1',rule:'S', upper:'S' },


              // { text: 'Seu nome de usuário', value: 'USUARIO',tag:'v-text', type:'text', cols:'2',rule:'S' },
              // { text: 'Email da Empresa', value: 'EMAIL',tag:'v-text', type:'text', cols:'4',rule:'S' },
              // { text: 'Senha', value: 'SENHA',tag:'v-text', type:'text', cols:'2',rule:'S' },
              // { text: 'Seu ', value: 'CNPJ_CPF',tag:'v-text', type:'text', cols:'3', width: this.w_cnpj, rule:'S', vmask:'cnpj' },
              // { text: 'CNPJ', value: 'CNPJ_CPF',tag:'v-text', type:'text', cols:'3', width: this.w_cnpj, rule:'S', vmask:'cnpj' },

              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

        }
        
        else if (tabela=='Aluno'){

          this.items_header = [
              
              // { text: 'Tipo', value: 'TIPO_CLIENTE',type:'text',tag:'v-select',tag_items:['CFC WEB','CFC','MEDCLINIC','CIVIC','CLINICA'],cols:'2', rule:'S', width:'90px'},
              
              { text: 'Nome', value: 'NOME', tag:'v-text', type:'text', cols:'6', width: this.w_nome, rule:'S', width:'210px' },


              { text: 'RG', value: 'RG',tag:'v-text', type:'text', cols:'2', width: this.w_cnpj, rule:'S' },
              { text: 'CPF', value: 'CPF',tag:'v-text', type:'text', cols:'2', width: this.w_cnpj, rule:'S', vmask:'cpf' },
              { text: 'Nascimento', value: 'DATA_NASCIMENTO',tag:'v-text', type:'text', cols:'2', width: this.w_cnpj, rule:'S', vmask:'data' },

              { text: 'WhatsApp', value: 'CELULAR',tag:'v-text', type:'text', cols:'2', width: this.w_fone, rule:'S',vmask:'cel' },

              { text: 'Email', value: 'EMAIL',tag:'v-text', type:'text', cols:'6',rule:'S' },
          

              // { text: 'Indicacao', value: 'INDICACAO',type:'text',tag:'v-select',tag_items:['GOOGLE','CLIENTES','WHATS','VENDEDOR','OUTROS'],cols:'2', rule:'N'},


              
              { text: 'CEP', value: 'CEP',tag:'v-text', type:'text', cols:'2',rule:'S',vmask:'cep' },
              { text: 'Endereço - Nº', value: 'ENDERECO',tag:'v-text', type:'text', cols:'5',rule:'S' },
              { text: 'Cidade', value: 'CIDADE',tag:'v-text', type:'text', cols:'3',rule:'S' },
              { text: 'Bairro', value: 'BAIRRO',tag:'v-text', type:'text', cols:'3',rule:'S' },
              { text: 'UF', value: 'UF',tag:'v-text', type:'text', cols:'1',rule:'S', upper:'S' },

              { text: 'Observação', value: 'OBSERVACAO',tag:'v-text', type:'text', cols:'12',rule:'N', upper:'N' },

              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

        }

        // setTimeout(function(){
        //   this.dialog_tabela = true
        // }.bind(this), 222);

    },

    async salvar_senha(){

      let dados = this.item_edita

      if ((dados.USER_EMAIL != dados.USER_X_EMAIL)){
        alert('E-mail não confere!')
        return false
      }

      this.overlay = true

      // const token = Math.floor(Math.random() * 900000) + 100000;
      let r = await gerar_token()
      r = r.slice(0, 6)
      r = r.toLowerCase()
      
      let senha = r
      
      console.log('today:', get_data_hora('today'));

      let sql = `INSERT INTO usuarios
 
        (
          ATIVO          ,
          DATA           ,
          CPF            ,
          EMAIL          ,
          NOME           ,
          SENHA          ,
          SUPER          ,
          NOME_CLIENTE   ,
          CODIGO_CLIENTE 

        )

       SELECT

        'S',
        '${get_data_hora('today')}' ,
        '${dados.USER_CPF}',
        '${dados.USER_EMAIL}',
        '${dados.USER_USUARIO}',
        '${senha}',
        'S',
        '${dados.NOME}',
        (SELECT CODIGO FROM clientes WHERE CNPJ_CPF = '${dados.CNPJ_CPF}' LIMIT 1);
       `

       // -------------------- SQL TESTE SO PARA DAR RETORNO E EXECUTAR OS ALERTAS
      //  sql = `update tarefas set codigo = 1 where codigo = 1`


      // console.log('sql:', sql);
      let res = null
      let rr  = null

      if(res =  await crud_sql_canal(sql)){
        console.log('res:', res);

        if (res.includes('sucesso')){

          this.alertaAqui(`Login salvo com sucesso!`, '', 'blue', 2000)

          
          // let msg_whats = `*Sistemas DSi*:\n*${this.item_edita.NOME}* Seu código de verificação é *${token}*`
          let msg_whats = `
*Acesse*:
 *dsi.app.br*

 *Usuário:*  *Digite seu CPF*
 *Senha:*     ${senha}

*Vídeo de Demonstração do DSi CFC* 
 https://youtu.be/saMCoXXFuQg

Qualquer dúvida estaremos à disposição 😉
*Sistemas DSi*
          `

          // ENVIA WHATS COM A SENHA
          // console.log('dados::', dados);
          //envia para o whatsapp tambem a senha
          let img = `https://dsi.app.br/logos/nova_dsi2.png`
          await send_whats_api_45('NOVO CLIENTE - VALIDAR', 'NOVO_CLIENTE.VUE', 'DSI -'+ dados.NOME , dados.CELULAR, msg_whats, null,null,img);//envia zap





          // ENVIA EMAIL COM A SENHA
          let msg_email = `Olá <b>${dados.USER_USUARIO}</b> sua senha provisória é: <b>${senha}</b> `
          // adicionou senha com sucesso..agora envia email
          try {

              if (rr =  await enviar_email_45(dados.USER_EMAIL, 'Novo Login', msg_email)){
                  console.log('r:', rr);
                  if (rr == 'OK'){

                    this.abre_modal('Verifique seu E-mail', `Sua <b>senha</b> foi enviada para seu Email e WhatsApp ! <br> Verique sua caixa de <b>entrada</b> e também sua caixa de <b>Spam</b>`,'redirect', 'ACESSAR app')

                  }
                  else{
                    this.abre_modal('E-mail não enviado', 'Não foi possível enviar o e-mail','','')
                  }
              }

          } catch (error) {

            setTimeout(function(){
              this.abre_modal(`Solicitando sua senha por e-mail`,`<br>Na janela de login do <b>App</b> clique em <b>Esqueci minha Senha</b> para que seja enviada uma nova senha para seu e-mail`,'redirect','acessar app')

            }.bind(this), 4000);
          }


        
        }

      }


      this.dialog_tabela = false
      this.overlay       = false
    },


    async salvar_aluno(){

      let dados = this.item_edita
      // console.log('dados:', dados);
      
      // console.log('today:', get_data_hora('today'));


      // if (this.TESTE){//apago antes de add
      //    alert('delete from clientes codigo>431...')
      //    await crud_sql_canal(`delete from clientes where codigo >= 431; delete from usuarios where codigo >=40; delete from usuarios where codigo_cliente >=431;`)
      // }


      // verifico se ja tem cliente cadastrado com o cnpj
      let retorno   = null
      let sql_query = `SELECT CODIGO_ALUNO,ATIVO, NOME FROM alunos WHERE CPF = '${dados.CPF}'`
      // console.log('sql_query:', sql_query);
      if(retorno = await crud_abrir_tabela_ponte( this.ide , sql_query)){
        //  console.log('retorno:', retorno);
         if (retorno.length>0){
          // ja tem cadastro

          this.alertaAqui(`CPF já Cadastrado: ${retorno[0].NOME}`,'','orange', 3000)
          
          // const retorno = await send_whats_api_45('NOVO CLIENTE', 'MODELO', 'DSI -'+dados.NOME , dados.CELULAR, `Ola`);//envia zap


          this.dialog_tabela = true
          return false
         }
      }
      
      // return false
      
      this.overlay = true

      let nascimento
      try{
         nascimento = this.transformarData(dados.DATA_NASCIMENTO);
      } catch (error) {}

      // insere verificando se ja existe cnpj cadastrado..evitar duplicidade
      let sql = `INSERT INTO alunos
 
        (
          CODIGO_ALUNO      ,
          STATUS            ,
          ATIVO             ,
          NOME              ,
          CPF               ,
          RG                ,
          CELULAR           ,
          EMAIL             ,
          CEP               ,
          ENDERECO          ,
          CIDADE            ,
          BAIRRO            ,
          UF                ,
          OBSERVACAO        ,
          DATA_CADASTRADO   ,
          DATA_NASCIMENTO   
        )

       SELECT
      
         IFNULL((SELECT MAX(CODIGO_ALUNO) FROM alunos), 0) + 1,
        'ONLINE',
        'S',
        '${dados.NOME}',
        '${dados.CPF}',
        '${dados.RG}',
        '${dados.CELULAR}',
        '${dados.EMAIL}',
        '${dados.CEP}',
        '${dados.ENDERECO}',
        '${dados.CIDADE}',
        '${dados.BAIRRO}',
        '${dados.UF}',
        '${dados.OBSERVACAO}',
        '${get_data_hora('today')}',
        '${nascimento}'
      FROM
        DUAL
      WHERE
        NOT EXISTS (
          SELECT 1 FROM alunos WHERE CPF = '${dados.CPF}'
        );
      `




      // console.log('sql:', sql);
      // return false

      
      let res =  await crud_sql_ponte(this.ide, sql)
      // console.log('res:', res);
      if (res.includes('sucesso')){
        this.alertaAqui('Cadastro realizado com sucesso!','', 'blue', 2000)
          
    
        // manda zap me avisando de novo cliente
        // this.avisa_zap_45('44999685172',`*TESTAR CFC*:\n *${dados.NOME}* - ${dados.CIDADE}-${dados.UF}`)

        // this.tabela('Login')

      
      }
      else{
        this.alertaAqui('Algo não deu certo!','tente novamente mais tarde!', 'red', 3000)
      }

        this.dialog_tabela = false

        this.overlay = false

    },

    async salvar_cadastro(){

      let dados = this.item_edita
      // console.log('today:', get_data_hora('today'));



      if (!dados.EMAIL){
        alert('E-mail não definido!')
        return false
      }

      if ((!dados.CELULAR)){
        alert('WhatsApp não definido!')
        return false
      }



      if (this.TESTE){//apago antes de add
         alert('delete from clientes codigo>431...')
         await crud_sql_canal(`delete from clientes where codigo >= 431; delete from usuarios where codigo >=40; delete from usuarios where codigo_cliente >=431;`)
      }


      // verifico se ja tem cliente cadastrado com o cnpj
      let retorno = null
      if(retorno = await crud_abrir_tabela_canal(`SELECT CODIGO,SITUACAO FROM clientes WHERE CNPJ_CPF = '${dados.CNPJ_CPF}'`)){
         console.log('retorno:', retorno);
         if (retorno.length>0){
          // ja tem cadastro

          this.alertaAqui('CNPJ já Cadastrado!','','orange', 3000)
          
          // const retorno = await send_whats_api_45('NOVO CLIENTE', 'MODELO', 'DSI -'+dados.NOME , dados.CELULAR, `Ola`);//envia zap


          this.dialog_tabela = true
          return false
         }
      }
      

      this.overlay = true

      // insere verificando se ja existe cnpj cadastrado..evitar duplicidade
      let sql = `INSERT INTO clientes
 
        (
          SITUACAO       ,
          NOME           ,
          CNPJ_CPF       ,
          TIPO_CLIENTE   ,
          DIA_VENCIMENTO ,
          CELULAR        ,
          FONE           ,
          INDICACAO      ,
          EMAIL          ,
          CEP            ,
          ENDERECO       ,
          CIDADE         ,
          BAIRRO         ,
          UF             ,
          BLOQUEAR       ,
          COBRANCA_WHATS ,
          FORMA_PAGAMENTO,
          DATA_CADASTRADO,
          DATA_TESTE_ACABA
        )

       SELECT
      
        'TESTE',
        '${dados.NOME}',
        '${dados.CNPJ_CPF}',
        '${dados.TIPO_CLIENTE}',
        '${dados.DIA_VENCIMENTO}',
        '${dados.CELULAR}',
        '${dados.FONE}',
        '${dados.INDICACAO}',
        '${dados.EMAIL}',
        '${dados.CEP}',
        '${dados.ENDERECO}',
        '${dados.CIDADE}',
        '${dados.BAIRRO}',
        '${dados.UF}',
        'N',
        'S',
        'BOLETO',
        '${get_data_hora('today')}',
        '${get_data_hora('quinze_dias')}'
      FROM DUAL
      WHERE NOT EXISTS (
        SELECT CODIGO FROM clientes WHERE CNPJ_CPF = '${dados.CNPJ_CPF}'
      );
      `


      let sql_assinatura = `INSERT INTO assinaturas
      (
        SITUACAO        ,
        DATA            ,
        DATA_REAJUSTAR  ,
        double_VALOR    ,
        SERVICO         ,
        REAJUSTE        ,
        CODIGO_CLIENTE  ,
        ANO_REAJUSTOU
      )
      VALUES(
        'TESTE'       ,
        '${get_data_hora('quinze_dias')}',
        '${get_data_hora('um_ano')}',
        '169.90',
        'ASSINATURA CFC',
        '10',
        (SELECT CODIGO FROM clientes WHERE CNPJ_CPF = '${dados.CNPJ_CPF}'),
        '${get_data_hora('ano_atual')}'
      );
      `
      // console.log('sql:', sql);
      // console.log('sql_assinatura:', sql_assinatura);

      
      let res =  await crud_sql_canal(sql + sql_assinatura)
      console.log('res:', res);
      if (res.includes('sucesso')){
        this.alertaAqui('Conta da Empresa realizada com sucesso!','', 'blue', 2000)
          
    
        // manda zap me avisando de novo cliente
        this.avisa_zap_45('44999685172',`*TESTAR CFC*:\n *${dados.NOME}* - ${dados.CIDADE}-${dados.UF}`)

        this.tabela('Login')

        setTimeout(function(){

          this.$set(this.item_edita, 'USER_CPF', '')//algum bug acaba trazendo os valores do campo CNPJ_CPF...nao sei resolver..ai estou apagando por aqui mesmo
          this.dialog_tabela = true
        }.bind(this), 3000);
      }
      else{
        this.alertaAqui('Algo não deu certo!','tente novamente mais tarde!', 'red', 3000)
      }

        this.dialog_tabela = false

        this.overlay = false

    },

    getVmask(qual,valor){
            
      // console.log('getVmask:', qual);return null

        if (qual == 'cel'){
            return "(##) #####-####"
        }
        else if (qual == 'fone'){
            return "(##) ####-####"
        }
        else if (qual == 'cnpj'){
            return "##.###.###/####-##"
        }
        else if (qual == 'cpf'){
            return "###.###.###-##"
        }
        else if (qual == 'cep'){
            return "#####-###"
        }
        else if (qual == 'data'){
            return "##/##/####"
        }
        else{
            return null
        }
    },

    async meu_keyup(event,campo,upper){
  
      //identifica se o campo é double e muda o display
      if ((campo.includes('double_') || campo.includes('VALOR')) ){
        this.item_edita[campo] =  this.moeda(event)
        // @keyup="editedItem.double_credito = moeda($event)"
      }
      else if (campo.includes('CEP')) {

        let retorno = null
        if(retorno = await consulta_cep(this.item_edita[campo])){
          // console.log('retorno:', retorno);

          this.$set(this.item_edita, 'ENDERECO', retorno.ENDERECO)
          this.$set(this.item_edita, 'CIDADE', retorno.CIDADE)
          this.$set(this.item_edita, 'BAIRRO', retorno.BAIRRO)
          this.$set(this.item_edita, 'UF', retorno.UF)
        }
      }
      else{
        if ((campo.includes('EMAIL')==false) &&
          (campo.includes('SENHA')==false) &&
          (campo.includes('SENHA_WEB')==false)){

            if (upper !=='N'){
              this.allUpper(event, campo)
            }
            
        }
      }
    },

    allUpper(event, field){
      let a = event.target.value;//captura o valor do event
      if (a!==''){  
          a = a.toUpperCase();
          this.item_edita[field] = a;
      } 
    },
  
    firstUpper(event, field){
      // console.log(event);
      let a = event.target.value;//captura o valor do event
      if (a!=='' && a.length==1){  
        a = a.toUpperCase();
        this.item_edita[field] = a;
      } 
    },



  },

}
</script>

<style scoped>

 .v-main__wrap{
    background-color: #273746; 
 }

 .v-main__wrapX{
    /* background-color: #273746; Substitua pela cor desejada em formato hexadecimal, RGB ou nome de cor */

    background-image: url('http://localhost/bc.png'); /* Substitua pelo caminho da sua imagem */
    background-size: cover; /* Ajusta a imagem para cobrir todo o fundo */
    background-repeat: no-repeat; /* Evita repetição da imagem */
    background-attachment: fixed; /* Mantém a imagem fixa enquanto você rola a página */
  }

  .background-div2 {
  background-image: url('http://localhost/images/black2.jpg'); /* Substitua pelo caminho da sua imagem */
  background-size: cover; /* Redimensiona a imagem para cobrir toda a div */
  background-position: center; /* Centraliza a imagem na div */
  width: 100%;
  height: 100vh; /* Tamanho total da tela */   
 }

 .background-div {
  background: linear-gradient(to bottom, #330033, #000066);
  margin: 0;
  padding: 0;
  height: 100vh;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  color: #fff; /* Define a cor do texto para branco ou outra cor visível. */
  font-family: Arial, sans-serif; /* Define a fonte desejada. */
}

</style>